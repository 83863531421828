import DataTab from '@rio-cloud/rio-uikit/DataTab';
import DataTabs from '@rio-cloud/rio-uikit/DataTabs';
import SimpleTooltip from '@rio-cloud/rio-uikit/SimpleTooltip';
import ExpanderPanel from '@rio-cloud/rio-uikit/ExpanderPanel';
import ContentLoader from '@rio-cloud/rio-uikit/ContentLoader';
import React, { useState } from 'react';
import { Criticality } from '../../generated/GeneratedApiTypes';
import { useFetchLifeTimeStatusQuery } from './api/maintenanceHistoryApi';
import { useMatch } from 'react-router';
import { FormattedDate, FormattedMessage } from 'react-intl';

const TABS = {
    ENGINE: 'tab1',
} as const;

const TEMPERATURE_UNIT = ' °C';
const PERCENTAGE_UNIT = '%';
const RPM_UNIT = ' RPM';
const SECONDS_UNIT = ' s';

const Recommendation = (status: Criticality) => {
    if (status !== 'DANGER') {
        return <></>;
    }

    return (
        <div className={'panel panel-default border-color-danger'}>
            <div className={'bg-critical'}>
                <div className="panel-body">
                    <div className="text-color-black">
                        <h5 className="text-size-16 margin-top-0">
                            <span className={'rioglyph rioglyph-info-sign margin-right-5 text-color-danger'} />
                            <FormattedMessage id="intl-msg:maintenanceHistory.lifetime.recommendation" />
                        </h5>
                        <div className="margin-left-25">
                            <ul>
                                <li>
                                    <FormattedMessage
                                        id="intl-msg:maintenanceHistory.lifetime.recommendation.010"
                                        values={{
                                            link: (
                                                <>
                                                    <a
                                                        href={
                                                            'https://secured.man.eu/vehicleexplorerui/?target=SI&man_language=en&nav=false'
                                                        }
                                                        className={'text-decoration-underline'}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        5458SM
                                                    </a>
                                                </>
                                            ),
                                        }}
                                    />
                                </li>
                                <li>
                                    <FormattedMessage
                                        id="intl-msg:maintenanceHistory.lifetime.recommendation.020"
                                        values={{
                                            link: (
                                                <>
                                                    <a
                                                        href={
                                                            'https://secured.man.eu/vehicleexplorerui/?target=SI&man_language=en&nav=false'
                                                        }
                                                        className={'text-decoration-underline'}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        6883SM
                                                    </a>
                                                </>
                                            ),
                                        }}
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const StatusIcon = ({ status }: { status: Criticality }) => (
    <>
        <span
            className={`rioglyph rioglyph-${status === 'DANGER' ? 'error' : 'ok'}-sign text-size-16 margin-y-auto margin-right-5`}
        />
        <span>
            <FormattedMessage
                id={`intl-msg:maintenanceHistory.lifetime.vehicle${status === 'DANGER' ? 'Critical' : 'Ok'}`}
            />
        </span>
    </>
);

const DataTabTitle = () => (
    <>
        <span className="rioglyph rioglyph-engine text-size-16" />
        &nbsp;
        <FormattedMessage id="intl-msg:maintenanceHistory.component.engine" />
    </>
);

const LoadingState = () => (
    <ExpanderPanel open title={<DataTabTitle />} bsStyle="default">
        <ContentLoader height={80} />
    </ExpanderPanel>
);

interface SingleStatusPanelProps {
    dataTestId: string;
    status: Criticality;
    value: number;
    unit: string;
    titleI18n: string;
    tooltip?: React.ReactElement;
    panelExtraClasses?: string;
}

const SingleStatusPanel = ({
    dataTestId,
    status,
    value,
    unit,
    titleI18n,
    tooltip,
    panelExtraClasses,
}: SingleStatusPanelProps) => (
    <div className={`col-xs-12 col-sm-6 col-md-6 display-flex ${panelExtraClasses}`}>
        <div
            className={`panel panel-default margin-bottom-0 width-100pct ${status === 'DANGER' ? 'status-critical' : ''}`}
            data-testid={dataTestId}
        >
            <div className="panel-body">
                <div className={'display-flex'}>
                    <span className={'text-size-14'}>
                        <FormattedMessage id={titleI18n} />
                    </span>
                </div>
                <div>
                    {value}
                    {unit}
                    {tooltip}
                </div>
            </div>
        </div>
    </div>
);

interface DoubleStatusPanelProps {
    dataTestId: string;
    status: Criticality;
    value: number;
    unit: string;
    titleI18n: string;
    secondValue: number;
    secondUnit: string;
    secondTitleI18n: string;
    panelExtraClasses?: string;
}

const DoubleStatusPanel = ({
    dataTestId,
    status,
    value,
    unit,
    titleI18n,
    panelExtraClasses,
    secondValue,
    secondUnit,
    secondTitleI18n,
}: DoubleStatusPanelProps) => (
    <div className={`col-xs-12 col-sm-6 col-md-6 display-flex ${panelExtraClasses}`}>
        <div
            className={`panel panel-default margin-bottom-0 width-100pct ${status === 'DANGER' ? 'status-critical' : ''}`}
            data-testid={dataTestId}
        >
            <div className="panel-body">
                <div className={'display-flex row'}>
                    <div className={'display-flex flex-column col-xs-12 col-sm-6 col-md-6'}>
                        <span className={'text-size-14'}>
                            <FormattedMessage id={titleI18n} />
                        </span>
                        <div>
                            {value} {unit}
                        </div>
                    </div>

                    {status === 'DANGER' && (
                        <div className={'display-flex flex-column col-xs-12 col-sm-6 col-md-6'}>
                            <span className={'text-size-14'}>
                                <FormattedMessage id={secondTitleI18n} />
                            </span>

                            <div>
                                {secondValue} {secondUnit}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </div>
);

interface LifetimeStatusProps {
    setShowError: (show: boolean) => void;
}

const LifetimeStatus = ({ setShowError }: LifetimeStatusProps) => {
    const assetId = useMatch('/:assetId')?.params.assetId;
    const [_, setTabKey] = useState('tab1');

    const { isLoading, isError, data } = useFetchLifeTimeStatusQuery(assetId!!);

    if (isLoading) return <LoadingState />;
    if (isError || (data !== undefined && data.overall_lifetime_status === 'UNDEFINED')) {
        setShowError(true);
        return <></>;
    }

    const expanderPanelTitle = (status: Criticality) => (
        <span className={status === 'DANGER' ? 'text-color-danger' : ''}>
            <StatusIcon status={status} />
        </span>
    );

    if (data !== undefined) {
        return (
            <>
                <div className={'display-flex gap-5 margin-bottom-10'}>
                    <span className={'rioglyph rioglyph-info-sign text-size-16 margin-y-auto'} />
                    <FormattedMessage
                        id="intl-msg:maintenanceHistory.lifetime.status.information"
                        values={{
                            date: <FormattedDate value={data.timestamp} year="numeric" month="numeric" day="numeric" />,
                        }}
                    />
                </div>

                <ExpanderPanel
                    open={data.overall_lifetime_status === 'DANGER'}
                    title={expanderPanelTitle(data.overall_lifetime_status)}
                    bsStyle="default"
                >
                    <DataTabs
                        activeKey={TABS.ENGINE}
                        onSelectTab={(newTabKey) => setTabKey(newTabKey)}
                        bordered={false}
                        disableTransition
                        tabsWrapperClassName="bg-white"
                        arrowClassName="left-10 right-10 width-auto bg-primary rotate-0 height-2 bottom-0"
                        tabClassName="bg-lightest"
                        tabHoverClassName="bg-white"
                        tabActiveClassName="bg-white padding-y-20"
                        tabContentClassName="rounded-bottom bg-white"
                    >
                        <DataTab tabKey={TABS.ENGINE} title={<DataTabTitle />}>
                            <div className={'equal-height row'}>
                                <SingleStatusPanel
                                    dataTestId="engine-oil-change-status-panel"
                                    status={data.max_engine_oil_change_status.status}
                                    value={data.max_engine_oil_change_status.percentage!!}
                                    unit={PERCENTAGE_UNIT}
                                    titleI18n="intl-msg:maintenanceHistory.lifetime.maximumEngineOilChangeOverdue"
                                    tooltip={
                                        <SimpleTooltip
                                            content={
                                                <FormattedMessage
                                                    id="intl-msg:maintenanceHistory.lifetime.maximumEngineOilChangeOverdue.tooltip"
                                                    values={{
                                                        date: (
                                                            <FormattedDate
                                                                value={data.max_engine_oil_change_status.service_date}
                                                                year="numeric"
                                                                month="numeric"
                                                                day="numeric"
                                                            />
                                                        ),
                                                    }}
                                                />
                                            }
                                        >
                                            <span
                                                className={'rioglyph rioglyph-info-sign text-size-16 margin-left-5'}
                                            />
                                        </SimpleTooltip>
                                    }
                                />

                                <SingleStatusPanel
                                    dataTestId="engine-oil-panel"
                                    status={data.max_engine_oil_status.status}
                                    value={data.max_engine_oil_status.temperature!!}
                                    unit={TEMPERATURE_UNIT}
                                    titleI18n="intl-msg:maintenanceHistory.lifetime.maximumEngineOilTemperature"
                                />

                                <SingleStatusPanel
                                    dataTestId="coolant-panel"
                                    status={data.max_coolant_status.status}
                                    value={data.max_coolant_status.temperature!!}
                                    unit={TEMPERATURE_UNIT}
                                    titleI18n="intl-msg:maintenanceHistory.lifetime.maximumCoolantTemperature"
                                    panelExtraClasses={'margin-top-10'}
                                />

                                <DoubleStatusPanel
                                    dataTestId="engine-speed-panel"
                                    status={data.max_engine_speed.status}
                                    value={data.max_engine_speed.rpm!!}
                                    unit={RPM_UNIT}
                                    titleI18n="intl-msg:maintenanceHistory.lifetime.maximumEngineSpeed"
                                    secondValue={data.max_engine_speed.max_engine_over_speed_time!!}
                                    secondUnit={SECONDS_UNIT}
                                    secondTitleI18n="intl-msg:maintenanceHistory.lifetime.engineOverspeedTime"
                                    panelExtraClasses={'margin-top-10'}
                                />
                            </div>
                            {data.recommendation && <div className={'margin-top-15'}>{Recommendation('DANGER')}</div>}
                        </DataTab>
                        {/* The following DataTab elements were added to shrink the Engine one to the expected size */}
                        <DataTab tabKey={'unused-1'} title={''} className={'disabled-tab'}>
                            <div className={'equal-height row visibility-hidden'}></div>
                        </DataTab>
                        <DataTab tabKey={'unused-2'} title={''} className={'disabled-tab'}>
                            <div className={'equal-height row visibility-hidden'}></div>
                        </DataTab>
                        <DataTab tabKey={'unused-3'} title={''} className={'disabled-tab'}>
                            <div className={'equal-height row visibility-hidden'}></div>
                        </DataTab>
                    </DataTabs>
                </ExpanderPanel>
            </>
        );
    }
};

export default LifetimeStatus;
